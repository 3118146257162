<template>
  <div class="faq flex column pt-89 pb-89 container">
    <div class="faq__title">{{HeaderTitle}}</div>
    <div class="faq__item" v-for="(faq, index) in faqs" :key="faq.title" @click="activeFaq(index)">
      <div class="faq__item-title pointer">{{ faq.title }}</div>
      <div class="faq__item-content">
        <slide-up-down class="faq__dropdown flex j-btw" :modelValue="selectedFaqIndex === index" :duration="400">
          <img v-if="faq.imageUrl" :src="faq.imageUrl" alt="image"/>
          <div v-html="faq.content"></div>
        </slide-up-down>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import SlideUpDown from 'vue3-slide-up-down';

let props = defineProps({
  contentData: {
    type: Object,
  }
})

const selectedFaqIndex = ref(null);

const activeFaq = (index) => {
  if (selectedFaqIndex.value === index) {
    selectedFaqIndex.value = null;
  } else {
    selectedFaqIndex.value = index;
  }
};
const faqs = computed(() => props.contentData?.data?.list?.map(item => ({
  ...item,
  imageUrl: item?.image?.[0]?.devices?.desktop
})))

const HeaderTitle = computed(() =>props.contentData?.conf?.header?.title)
</script>

<style lang="scss">
.faq {
  border-bottom: 1px solid rgba(0, 0, 0, .7);

  &__title {
    font-size: 55px;
    line-height: 89px;
    margin-bottom: 55px;
    @include mq(tablet) {
      font-size: 34px;
      line-height: 77px;
    }
    @include mq(mobile) {
      font-size: 34px;
      line-height: 43px;
    }
  }

  &__item {
    &-title {
      font-size: 34px;
      line-height: 105px;
      text-decoration: underline;

      @include mq(tablet) {
        font-size: 21px;
        line-height: 93px;
      }
      @include mq(tablet-sm) {
        font-size: 21px;
        line-height: 56px;
      }
    }
  }

  &__dropdown {
    margin-top: 33px;

    img {
      width: 480px;
      height: 409px;
      margin-right: 40px;
      object-fit: cover;
      @include mq(desktop-sm) {
        width: 393px;
        height: 401px;
      }
      @include mq(tablet) {
        width: 349px;
        height: 325px;
      }
    }

    p {
      font-size: 34px;
      line-height: 41px;
      @include mq(desktop-sm) {
        font-size: 28px;
        line-height: 34px;
      }
      @include mq(tablet) {
        font-size: 24px;
        line-height: 29px;
      }
      @include mq(tablet-sm) {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
</style>
